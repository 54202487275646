import React from 'react'
import styles from './styles/MerchantPage.module.css';
import TopBar from '../components/navigation/TopBar';
import Hero from '../components/Hero';
import HowItWorksFeaturesMerchant from '../components/how-it-works/HowItWorksFeaturesMerchant';
import Footer from '../components/footers/Footer';
import HowItWorksMerchant from '../components/how-it-works/HowItWorksMerchant';
import Video from '../components/Video';
import Testimonials from '../components/Testimonials';
import Network from '../components/footers/Network';
import CTAMerchant from '../components/footers/CTAMerchant';
import AiChat from '../components/AiChat';

const MerchantPage = props => {
    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="business" />
            <Hero />
            <HowItWorksFeaturesMerchant />
            <HowItWorksMerchant />
            <Video/>
            <Testimonials/>
            <Network/>
            <CTAMerchant/>
            <Footer />
        </div>
    )
}

export default MerchantPage