import React from 'react'
import styles from './HowItWorksTaxi.module.css';

const STEPS = {
    taxi: [
        {
            title: "Step 1: Initiate a new payment",
            desc: "In your Merqury app or online dashboard, tap New Payment and enter the payment amount."
        },
        {
            title: "Step 2: Share a link or your QR code",
            desc: "Send a payment link to a remote customer, or show your QR code to an in-person customer."
        },
        {
            title: "Step 3: Customer approves payment",
            desc: "Your customer scans the QR code or clicks the link - they are taken straight to their banking app and approve the payment with a click."
        },
        {
            title: "Step 4: Instant settlement",
            desc: "That's it! Money is in your bank instantly, and you both get a confirmation message"
        },
    ]
}

const HowItWorksTaxi = (props) => {
    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <div className={styles['top-section']}>
                    <div className={styles.left}>
                        <h2>How It Works</h2>
                        <span className={styles.desc}>
                            A Merqury instant bank transfer moves money securely from your customer’s bank account into your bank account by using regulator approved Open Banking APIs. Funds clear and are available to be spent within a few seconds.
                            Where customers prefer to pay the old fashioned way, Merqury allows you to tap a card on your phone to take a traditional card payment just as if it were a payment terminal. Also works with Apple Pay and Google Pay.
                        </span>
                    </div>
                    <div className={styles.right}>
                        <img alt="header" src={require(`../../assets/images/how-it-works-header.png`)} />
                    </div>
                </div>
                <div className={styles['bottom-section']}>
                    {
                        STEPS["taxi"].map((item, idx) => {
                            return (
                                <div key={idx} className={styles['grid-item']}>
                                    <div className={styles.left}>
                                        <img alt="step" src={require(`../../assets/images/how-it-works/merchant/step-${idx}-new.png`)} />
                                    </div>
                                    <div className={styles.right}>
                                        <span>{item.title}</span>
                                        <span>{item.desc}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default HowItWorksTaxi
