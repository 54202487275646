import React, { useState } from 'react'
import styles from './styles/PricingSchool.module.css';
import ToggleButton from './ui/ToggleButton';
import Button from './ui/Button';

const PRICE_ITEMS = [
    {
        tag: "Smaller Schools",
        title: "< 350 Pupils",
        price: [50, 500],
        features: [
            "1,000 transactions per year",
            "Auto-chase feature",
            "Bulk upload tool",
            "Outstanding payments reconciliation"
        ],
        button: null
    },
    {
        tag: "Larger Schools",
        title: "350+ Pupils",
        price: [100, 1000],
        features: [
            "Unlimited transactions",
            "Auto-chase feature",
            "Bulk upload tool",
            "Outstanding payments reconciliation"
        ],
        button: null
    }
]

const PricingSchool = props => {

    const [periodIdx, setPeriodIdx] = useState(0);

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <h2 className={styles['header-title']}>Simple <span className={styles.highlight}>Pricing</span></h2>
                <span className={styles['header-desc']}>Trial Merqury free for 60 days with no questions asked cancellation if it doesn’t work for your school</span>

                <ToggleButton labelArr={["Billed Monthly", "Billed Yearly"]} activeLabelIdx={periodIdx}
                    onChange={(selectedIdx) => setPeriodIdx(selectedIdx)}
                    wrapperStyles={{ maxWidth: '400px', width: '100%', margin: '0px 0px 50px 0px' }} />

                <div className={styles['price-card-wrapper']}>
                    {PRICE_ITEMS.map((card, idx) => {
                        return (
                            <div key={idx} className={styles['price-card']}>
                                <span className={styles['tag']}>{card.tag}</span>
                                <span className={styles['title']}>{card.title}</span>
                                <span className={styles['price-text']}><span className={styles['highlight']}>£{card.price[periodIdx].toLocaleString("en-GB")}</span> / {periodIdx ? "year" : "month"}</span>
                                {card.features.map((feat, idx2) => {
                                    return (
                                        <div key={idx2} className={styles['feature-row']}>
                                            <img alt="box" src={require('../assets/images/box.png')} />
                                            <span>{feat}</span>
                                        </div>
                                    )
                                })}
                                <Button text="Try free for 60 days" wrapperClass={styles.button} onClick={() => {
                                    let urlToRedirect = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                                        ? `https://merchant-staging.merqury.co.uk/register`
                                        : `https://merchant.merqury.co.uk/register`;
                                    window.location.href = urlToRedirect
                                }} />
                            </div>
                        )
                    })}
                </div>
                <span className={styles['one-off-fee']}>*One-time setup fee of £195 will be charged</span>
            </div>
        </div>
    )
}

export default PricingSchool