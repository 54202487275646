import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import MerchantPage from './pages/MerchantPage';
import ContactUsModal from './components/modals/ContactUsModal';
import { AppContextProvider, AppContext } from './AppContext';
import { useContext, useEffect } from 'react';
import LegalModal from './components/LegalModal';
import ConsumerPage from './pages/ConsumerPage';
import PricingPage from './pages/PricingPage';
import FaqPage from './pages/FaqPage';
import SchoolPage from './pages/subpages/SchoolPage';
import OmsPage from './pages/subpages/OmsPage';
import TaxiFlyerPage from './pages/TaxiFlyerPage';
import MerchantTaxiPage from './pages/MerchantTaxiPage';

const PageLayout = ({ children }) => {
  const appContext = useContext(AppContext);
  return (
    <>
      {children}
      {appContext.showContactModal && <ContactUsModal onClose={appContext.setShowContactModal} />}
      {appContext.showLegalModal && <LegalModal />}
    </>
  )
}

const NavigateReferral = ({ ...props }) => {
  let { referralText } = useParams();
  useEffect(() => {
    if (referralText) {
      let urlToRedirect = `https://merchant.merqury.co.uk/register?referralText=${referralText}`;
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        urlToRedirect = `https://merchant-staging.merqury.co.uk/register?referralText=${referralText}`;
      }
      window.location.href = urlToRedirect
    }
  }, [referralText])
}

function App() {
  return (
    <AppContextProvider>
      <Routes>
        <Route path='/' element={<Navigate to={`/business`} />} />
        <Route path='/business' element={<PageLayout><MerchantPage /></PageLayout>} />
        <Route path='/education' element={<PageLayout><SchoolPage /></PageLayout>} />
        <Route path='/oms' element={<PageLayout><OmsPage /></PageLayout>} />
        <Route path='/pricing' element={<PageLayout><PricingPage /></PageLayout>} />
        <Route path='/faq/*' element={<PageLayout><FaqPage /></PageLayout>} />
        <Route path='/personal' element={<PageLayout><ConsumerPage /></PageLayout>} />       
        <Route path='/taxiflyer' element={<PageLayout><TaxiFlyerPage /></PageLayout>} />       
        <Route path=":referralText" element={<NavigateReferral />} />
        <Route path="/taxi" element={<PageLayout><MerchantTaxiPage /></PageLayout>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AppContextProvider>
  );
}

export default App;
