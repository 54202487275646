import React, { useContext } from 'react'
import styles from './styles/Hero.module.css';
import Button from './ui/Button';
import { AppContext } from '../AppContext';

const Hero = ({ mode }) => {

    const appContext = useContext(AppContext);

    const isPersonal = mode === "personal";
    const isSchool = mode === "school";
    const isOms = mode === "oms";
    const isTaxi = mode === "taxi";

    const printHeroTitle = () => {
        if (isPersonal) {
            return <>Easy payment requests <span className={styles.highlight}>for everyone</span></>
        } else if (isSchool) {
            return <>Secure School Fee Payments</>
        } else if (isOms) {
            return <>Take More Orders</>
        } else if (isTaxi) {
            return <>We Hate Card Fees</>
        } else {
            return <>We Hate Card Fees</>
        }
    }

    const printDesc = () => {
        if (isPersonal) {
            return `Get paid quickly and easily with Merqury. Send a simple payment link, or show your QR code. Merqs are paid instantly and with no cost. Ask your friends to Merq you to get paid back faster and without the hassle.`
        } else if (isSchool) {
            return `Merqury gives parents an easy, secure way to pay school and nursery fees and other expenses with complete peace of mind. Free up finance team time with instant notification, automated receipts, and unpaid bill autochasing. Easily share outstanding payment information with non-finance staff.`
        } else if (isOms) {
            return `Use Merqury Order Management to get more orders, increase average order value, receive more tips, save time, and deliver a better customer experience.`
        } else if (isTaxi) {
            return `Turn your phone into a payment terminal with the Merqury app. Sign up now to take card payments directly from your phone or use instant bank transfers instead to get instant pay-outs and pay just 0.5% per transaction.`
        } else {
            return `Get paid faster, for less, with Merqury. Say goodbye to card fees for good with Pay by Bank App technology. Or turn your phone into a card terminal and pay as low as 1% for all card types.`
        }
    }

    const printImg = () => {
        if (isPersonal) {
            return require(`../assets/images/hero-image-consumer2.png`)
        } else if (isSchool) {
            return require(`../assets/images/school-1.png`)
        } else if (isOms) {
            return require(`../assets/images/oms-1.png`)
        } else if (isTaxi) {
            return require(`../assets/images/hero-image-merchant-taxi.png`)
        } else {
            return require(`../assets/images/barber-1.png`)
        }
    }

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content ${!isPersonal ? styles.business : styles.personal}`}>

                {isPersonal &&
                    <>
                        <div className={styles.left}>
                            <h1 className={styles.title}>
                                {printHeroTitle()}
                            </h1>
                            <span className={styles.desc}>
                                {printDesc()}

                            </span>
                            <div className={styles['store-icons']}>
                                <a rel="noreferrer" href="https://apps.apple.com/us/app/merqury-sales/id6474265785" target="_blank">
                                    <img alt="apple-store" src={require(`../assets/images/apple-appstore.png`)} />
                                </a>
                                <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=uk.co.merqury.merchant" target="_blank" >
                                    <img alt="google-store" src={require(`../assets/images/google-play.png`)} />
                                </a>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <img alt="hero-img" src={printImg()} />
                        </div>
                    </>
                }

                {!isPersonal &&
                    <>
                        <div className={`${styles.left} ${isSchool ? styles.school : ''} ${isOms ? styles.oms : ''}`}>
                            <h1 className={styles.title}>
                                {printHeroTitle()}
                            </h1>
                            <span className={styles.desc}>
                                {printDesc()}
                            </span>
                            {!isSchool && <div className={styles['store-icons']}>
                                <a rel="noreferrer" href="https://apps.apple.com/us/app/merqury-sales/id6474265785" target="_blank">
                                    <img alt="apple-store" src={require(`../assets/images/apple-appstore.png`)} />
                                </a>
                                <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=uk.co.merqury.merchant" target="_blank" >
                                    <img alt="google-store" src={require(`../assets/images/google-play.png`)} />
                                </a>
                            </div>}
                            {isOms && <div className={styles['scroll-container']}>
                                <div className={styles.scroll}>
                                    {["Food Trucks", "Food Stalls", "Food Halls", "Catering Vans", "Restaurants", "Cafes", "Takeaaways", "Eateries", "Pop-up Stores"].map((item, idx) => {
                                        return (
                                            <div key={idx} className={styles['scroll-item']}>{item}</div>
                                        )
                                    })}
                                </div>
                            </div>}
                            {isSchool && <div className={styles['store-icons']}>
                                <Button text={"Enquire"} reverseColor={true} onClick={() => { appContext.setShowContactModal(true) }} />
                            </div>}
                        </div>
                        <div className={styles.right}>
                            <img alt="hero-img" src={printImg()} style={isTaxi ? { top: `calc(50% - 300px)` } : {}} />
                        </div>
                    </>
                }
            </div>

            {isSchool && <div className={styles['isba-div']}>
                <img alt="hero-img" src={require(`../assets/images/isba-partner.png`)} className={styles.isba} />
            </div>}
            {isOms && <div className={styles['ncass-div']}>
                <img alt="hero-img" src={require(`../assets/images/ncass-partner.png`)} className={styles.ncass} />
                </div>}
            {isTaxi && <div className={`${styles.quote}`}>
                <span className={`${styles.text}`} >“I love that I get money in my bank account straight away, I ask all my fares to pay with Merqury now”</span>
                <span className={`${styles.author}`}>– Ansar, taxi driver in Reading</span>
            </div>}

        </div>
    )
}

export default Hero