import React, { useState } from 'react'
import styles from './styles/Video.module.css';
import ToggleButton from './ui/ToggleButton';

const Video = ({ mode }) => {

    const embedId = mode === "school" ? "IJbRziBXqCo" : "bHiL70rZFxE";
    const isOms = mode === "oms";

    const [videoIdx, setVideoIdx] = useState(0);


    return (
        <div className={`${styles['main-content-wrapper']} ${(mode === "school" || mode === "oms") ? styles.school : ''}`}>
            <div className={`${styles.main} main-content`}>
                {mode === "school" && <h2 className={styles.h2}>See How It Works</h2>}
                {mode === "oms" && <h2 className={styles.h2}>See It in Action</h2>}
                {mode === "oms" &&
                    <ToggleButton labelArr={["Merchant View", "Customer View"]} activeLabelIdx={videoIdx}
                        onChange={(selectedIdx) => setVideoIdx(selectedIdx)}
                        wrapperStyles={{ maxWidth: '400px', width: '100%', margin: '0px auto 30px auto' }} />
                }
                {<iframe
                    src={`https://www.youtube.com/embed/${(isOms ? (videoIdx ? 'dtUeQFFo6BU' : 'oF5pTJKPcdI') : embedId)}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen
                    title="Embedded youtube"
                />}
                {mode === "school" && <h3 className={styles.h3}>Learn How Merqury Can Help With Fraud?</h3>}
                {mode === "school" &&
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="175" style={{ width: '100%', maxWidth: '660px', 'overflow': 'hidden', 'borderRadius': '10px', 'margin': '10px auto 0px auto' }}
                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                        src="https://embed.podcasts.apple.com/gb/podcast/isba-bursarcast-a-week-in-review-with-john-murphie/id1579932577?i=1000670159410"></iframe>
                }
            </div>
        </div>
    )
}

export default Video