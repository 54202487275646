import React, { useState } from 'react'
import TopBar from '../components/navigation/TopBar'
import styles from './styles/TaxiFlyerPage.module.css';
import CTAMerchant from '../components/footers/CTAMerchant';
import Footer from '../components/footers/Footer';
import ToggleButton from '../components/ui/ToggleButton';

const TaxiFlyerPage = props => {

    const [langIdx, setLandIdx] = useState(0);
    const landIndexToLand = ["hindi", "urdu", "punjabi"]

    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="business" activeSubMenuItem="tax-flyer" />
            <ToggleButton labelArr={["Hindi", "Urdu", "Punjabi"]} activeLabelIdx={langIdx}
                onChange={(selectedIdx) => setLandIdx(selectedIdx)}
                wrapperStyles={{ maxWidth: '400px', width: '100%', margin: '0px auto 30px auto' }} />
                <div className={styles['flyer-container']}>
                    <img src={require(`../assets/images/flyers/taxi-flyer-${landIndexToLand[langIdx]}-1.png`)} alt="flyer-1"/>
                    <img src={require(`../assets/images/flyers/taxi-flyer-${landIndexToLand[langIdx]}-2.png`)} alt="flyer-2"/>
                </div>
            <CTAMerchant />
            <Footer />
        </div>
    )
}

export default TaxiFlyerPage